<template>
  <div class="bar">
    <div :id="id" ref="myEchart" :style="{width: width,height:height}"></div>
  </div>

</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: "bar",
    data(){
      return {
        chart:undefined
      }
    },
    updated() {
      this.chart.resize();
    },
    mounted() {
      let _this=this;
      this.$nextTick(()=>{
        this.echarts_init()
      })
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pies'
      },
      trendsData:{
        type:Object,
        default: {}
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    watch:{
      trendsData:{
        handler(){
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      updata(){
        this.$nextTick(()=>{
          this.chart.resize();
        })
      },
      echarts_init(){
        if(this.chart) this.chart.dispose()
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));

        var option = {
          xAxis: {
            data: ['A', 'B', 'C', 'D', 'E']
          },
          yAxis: {},
          series: [
            {
              data: [10, 22, 28, 23, 19],
              type: 'line',
              smooth: true
            },
            {
              data: [10, 22, 28, 43, 49],
              type: 'line',
              smooth: true
            },
            {
              data: [5, 4, 3, 5, 10],
              type: 'line',
              smooth: true
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
