<template>
  <div class="bar">
    <div :id="id" ref="myEchart" :style="{width: width,height:height}"></div>
  </div>

</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: "bar",
    data(){
      return {
        chart:undefined
      }
    },
    updated() {
      this.chart.resize();
    },
    mounted() {
      let _this=this;
      this.$nextTick(()=>{
          this.echarts_init()
      })
      this.echarts_init()
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pies'
      },
      trendsData:{
        type:Object,
        default: {}
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    watch:{
      trendsData:{
        handler(){
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      // 生成双曲线数据
      generateHyperbolaData(a, b, step) {
    var data = [];
    for (var x = -a * 2; x <= a * 2; x += step) {
      var y1 = b * Math.sqrt((x * x) / (a * a) - 1);
      var y2 = -y1;
      if (isFinite(y1)) {
        data.push([x, y1]);
        data.push([x, y2]);
      }
    }
    return data;
  },
  updata(){
        this.$nextTick(()=>{
          // this.echarts_init()
          this.chart.resize();
        })
      },
      echarts_init(){
        if(this.chart) this.chart.dispose()
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));
        const colors = ['#215cc2', '#35497c'];
        var option = {
          title: {
            text: '有效量统计图',
            subtext: '数据截止到2024年',
            left: '2%',  // 水平居中
            top: '7%',
            textStyle: {
              fontWeight: 'bold',
              fontSize: 16,
              color: '#2D3748'
            },
            subtextStyle: {
              fontsize: 12,
              color: '#C1CDDA'
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['商标', '专利'],
            right: '7%',
            top: '10%',      // 距离顶部 10%
          },
          grid: {
            left: '10%',
            right:'10%',
            top: '25%',
            bottom: '10%'
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: this.trendsData.bar1.yearList
            }
          ],
          yAxis : [
            {
              name: '商标',
              type: 'value',
              nameLocation:"end",
              nameGap:10,
              minInterval: 1 ,// 设置 y 轴刻度的最小间隔为 1
              nameRotate:0,
              nameTextStyle:{
                fontSize: 16,
              },
              splitLine: {
                show: false  // 第二个Y轴不显示栅格线
              }
            },
            {
              name: '专利',

              type: 'value',
              nameLocation:"end",
              nameGap:10,
              nameRotate:0,
              minInterval: 1 ,// 设置 y 轴刻度的最小间隔为 1
              nameTextStyle:{
                fontSize: 16,
              },
              splitLine: {  // 配置栅格线
                lineStyle: {
                  color: '#E0E0E0',  // 栅格线颜色
                  width: 2
                },
                show: true,
                interval: function(index, value) {
                  // 通过返回 true 或 false 来决定是否显示当前栅格线
                  return index === 0;  // 只显示第一根线
                }
              }

            }
          ],
          series: [
            {
              name: '商标',
              type: 'line',
              smooth: true,  // 设置平滑曲线
              symbol: 'none',  // 去掉圆点
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(33,92,194, 0.6)' },  // 渐变起始颜色
                  { offset: 1, color: 'rgba(33,92,194, 0.2)' }   // 渐变结束颜色
                ])
              },
              yAxisIndex: 0,
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: colors[0], // 设置折线颜色为红色
                width: 2         // 线条宽度
              },
              areaStyle: {
                color: '#d0e2f3'  // 设置堆积面积颜色为红色，并带有50%的透明度
              },
              data: this.trendsData.bar2.countList
            },
            {
              name: '专利',
              type: 'line',
              smooth: true,  // 设置平滑曲线
              symbol: 'none',  // 去掉圆点
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(53,73,124, 0.6)' },  // 渐变起始颜色
                  { offset: 1, color: 'rgba(53,73,124, 0.2)' }   // 渐变结束颜色
                ])
              },
              yAxisIndex: 1,
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                color: colors[1], // 设置折线颜色为红色
                width: 2         // 线条宽度
              },
              data: this.trendsData.bar1.countList
            }
          ]}

        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
