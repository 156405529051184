<template>
  <div class="bar">
    <div :id="id" ref="myEchart" :style="{width: width,height:height}"></div>
  </div>

</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: "bar",
    data(){
      return {
        chart:undefined
      }
    },
    updated() {
      this.chart.resize();
    },
    mounted() {
      let _this=this;
      this.$nextTick(()=>{
        this.echarts_init()
      })
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pies'
      },
      trendsData:{
        type:Object,
        default: {}
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    watch:{
      trendsData:{
        handler(){
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      updata(){
        this.$nextTick(()=>{
          this.chart.resize();
        })
      },
      echarts_init(){
        if(this.chart) this.chart.dispose()
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));

        var option = {
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          grid:{
            show:false
          },
          xAxis: {
            type: 'category',
            data: this.trendsData.yearList
          },
          yAxis: [
            {
              type: 'value',
              position: 'left'
            }, {
              type: 'value',
              name: 'Evaporation',
              position: 'right',
              alignTicks: true,
            }
          ],
          series: [
            {
              data: this.trendsData.countList,
              type: 'bar',
              barWidth: '30%'
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
