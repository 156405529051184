<template>
  <div class="app-container home">
    <el-row class="row_title"  type="flex" justify="space-between" style="margin-bottom: 18px" >
      <el-col :span="12">
        <el-select @change="applicantChange"  class="selectApplicant" v-model="value" placeholder="选择要展示的申请人数据">
          <el-option
            v-for="item in applicant"
            :key="item.value"
            :label="item.text"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="row_title"  type="flex" justify="space-between" style="margin-bottom: 18px">
      <div  style="width: 49%">
        <div class="estimatedExpenses">
          <p class="Taltitle">本年度费用预估</p>
          <ul class="estimatedExpenses_content">
            <li>
              <p></p>
              <p class="title">年度</p>
              <p class="title">待缴数量</p>
              <p class="title">预估费用</p>
            </li>
            <li>
              <p class="title">{{evaluateAmount[0]?.amountName}}</p>
              <p class="data">{{evaluateAmount[0]?.amountYear}}</p>
              <p class="data">{{evaluateAmount[0]?.custodyCount}}</p>
              <p class="price"><i style="margin-right: 4px">¥</i>{{evaluateAmount[0]?.summaryAmount}}</p>
            </li>
            <li>
              <p class="title">{{evaluateAmount[1]?.amountName}}</p>
              <p class="data">{{evaluateAmount[1]?.amountYear}}</p>
              <p class="data">{{evaluateAmount[1]?.custodyCount}}</p>
              <p class="price"><i style="margin-right: 4px">¥</i>{{evaluateAmount[1]?.summaryAmount}}</p>
            </li>
          </ul>
        </div>
        <ul class="custodyVolume">
          <li style="margin-bottom: 18px;">
            <div>
              <p class="title">专利托管量</p>
              <p class="value"><span>{{queryHostingArr.applicantPatentCustodyCount}}/</span>{{queryHostingArr.patentCustodyCount}}</p>
            </div>
            <img src="../../assets/images/hostingPLatform/home/patent.png" alt="">
          </li>
          <li style="margin-bottom: 18px;">
            <div>
              <p class="title">商标托管量</p>
              <p class="value"><span>{{queryHostingArr.applicantTrademarkCustodyCount}}/</span>{{queryHostingArr.trademarkCustodyCount}}</p>
            </div>
            <img src="../../assets/images/hostingPLatform/home/tram.png" alt="">
          </li>
          <li>
            <div>
              <p class="title">软著托管量</p>
              <p class="value"><span>{{queryHostingArr.applicantSoftwareCustodyCount}}/</span>{{queryHostingArr.softwareCustodyCount}}</p>
            </div>
            <img src="../../assets/images/hostingPLatform/home/ruan.png" alt="">
          </li>
          <li>
            <div>
              <p class="title">资质托管量</p>
              <p class="value"><span>{{queryHostingArr.applicantCertificateCustodyCount}}/</span>{{queryHostingArr.certificateCustodyCount}}</p>
            </div>
            <img src="../../assets/images/hostingPLatform/home/zizhi.png" alt="">
          </li>
        </ul>
      </div>
      <div style="width: 49%">
        <div class="effectiveStatistics">
          <bars ref="echarts1" id="bar" style="width: 100%;height: 100%" :trendsData="{bar1: bar1, bar2: bar2}"></bars>
        </div>
      </div>
    </el-row>

    <!--    园区首页-->

    <el-row  >
      <el-col :span="24">
        <div class="manage">
          <div class="title">
            <div class="_title_">
              <span @click="yearFlagEven(true)" :class="[{'active': yearFlag}]">年费管理</span>
              <span @click="yearFlagEven(false)" :class="[{'active': !yearFlag}]">续展管理</span>
            </div>
            <div class="month">
              <span>本月到期 </span>
              <img @click="jump(yearFlag ? 1 : 2)" src="../../assets/images/hostingPLatform/home/Group.png" alt="">
            </div>
          </div>
          <div class="maturity">
            <div v-if="yearFlag">
              <ul class="maturity_title">
                <li>
                  <span>专利名称</span>
                  <span>申请号</span>
                  <span>专利状态</span>
                  <span>申请日期</span>
                  <span>到期时间</span>
                  <span>操作</span>
                </li>
              </ul>
              <ul >
                <li :key="index" v-for="(item,index) in patentRemindList">
                  <el-tooltip class="item" effect="dark" :content="item.patentName" placement="top">
                    <span>{{item.patentName}}</span>
                  </el-tooltip>

                  <span>{{item.applicationNo}}</span>
                  <span class="status"> <i :class="[{'you': item.patentLawStatus}, {'err': !item.patentLawStatus}]">{{item.patentLawStatus ? '有效' : '无效' }}</i></span>
                  <span>{{item.applicationDate}}</span>
                  <span>{{item.applicationDeadLine}}</span>
                  <span><el-button @click="goPayPatent(item)" type="text" size="small">去缴费</el-button></span>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul class="maturity_title">
                <li>
                  <span>商标图样</span>
                  <span>商标名称</span>
                  <span>申请号</span>
                  <span>商标状态</span>
                  <span>注册公告日</span>
                  <span>到期时间</span>
                  <span>操作</span>
                </li>
              </ul>
              <ul >
                <li :key="index" v-for="(item,index) in trademarkRemindList">
                  <span> <img style="height: 40px" :src="queryDataUrl(item.trademarkImageUrl)" alt=""></span>
                  <span>{{item.trademarkName}}</span>
                  <span>{{item.trademarkApplyNo}}</span>
                  <span class="status"> <i :class="[{'you': item.trademarkStatus.indexOf('无效') == -1}, {'err': item.trademarkStatus.indexOf('无效') != -1}]">{{item.trademarkStatus}}</i></span>
                  <span>{{item.registerDate}}</span>
                  <span>{{item.annualFeeDate}}</span>
                  <span><el-button @click="goPayTrademark(item)" type="text" size="small">去缴费</el-button></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import line from '@/components/chart/line'
  import bars from '@/components/chart/chart2'
  import bar from '@/components/chart/chart1'
  export default {
    name: "Index",
    data() {
      return {
        queryHostingArr: {},
        patentRemindList: [],
        trademarkRemindList: [],
        yearFlag: true,
        custodyNumber: {},
        value: 0,
        applicant: [],
        activeName: '专利有效量',
        list:[
          {
            lable: '专利有效量',
            show: true
          },
          {
            lable: '商标有效量',
            show: false
          },
          {
            lable: '软著量',
            show: false
          },
          {
            lable: '资质数量',
            show: false
          }
        ],
        //判断当前登陆用户角色 1.园区 park 2.公司 enterprise 3.组织机构 organization
        role: 'park',
        //知识产权管理
        propertyManagementData: [],
        //最近入驻企业
        settledEnterprisesData: [],
        //首页4个柱图
        bar1: [],
        bar2: [],
        bar3: [],
        bar4: [],
        //园区培训课程
        courseData: [],
        //知识产权统计
        listPropertyData: [],
        statisticsData: [],
        statisticsDatas: [],
        statisticsDatass: [],
        //企业首页--代理机构代理量统计
        organizationSummary:[],
        //企业IP量统计
        enterpriseIPData: [],
        // 版本号
        version: "3.8.5",
        enterpriseName: '',
        bigData: {},
        evaluateAmount: [],
      };
    },
    components:{
      bar,
      bars,
      line
    },
    watch:{
      activeName(){
        this.$nextTick(() => {
          this.$refs.echarts1.updata()
          this.$refs.echarts2.updata()
          this.$refs.echarts3.updata()
          this.$refs.echarts4.updata()
        })
      }
    },
    mounted(){

      this.editeApplicantName()
      //------------------------------------------------
    },
    methods: {
      yearFlagEven(val) {
        this.yearFlag = val
      },
      //选择申请人
      applicantChange(e){
        localStorage.setItem('applicant',this.applicant[e].text)
        //预估当年费用
        this.getEvaluateAmount()
        this.init_chart()
        this.init_table()
        this.queryHosting()
        //园区首页知识产权管理统计接口
        this.getAdministration()
        //入驻企业
        this.settledEnterprises()
        //培训列表
        this.getCourseList()
        //知识产权统计
        this.listProperty()
        //
        this.intellectualStatistics()
        //企业代理机构
        this.volumeStatistics()
        //企业IP数量
        this.enterpriseIP()
      },
      getEvaluateAmount() {
        this.$axios.post('/custody/home/evaluate/amount',{
          applicantName: localStorage.getItem('applicant')
        }).then(({data}) => {
          if(data.code == 0){
            this.evaluateAmount = data.data
          }
        })
      },
      editeApplicantName() {
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicant = data.data.filter(item => item.storeFlag)
            this.applicant = this.applicant.map((item, index) => {
              return {
                "value": index,
                "text": item.applicantName
              }
            })
            if (this.applicant.length > 0) {
              localStorage.setItem('applicant',this.applicant[0].text)
              //预估当年费用
              this.getEvaluateAmount()
              this.init_chart()
              this.init_table()
              this.queryHosting()
              //园区首页知识产权管理统计接口
              this.getAdministration()
              //入驻企业
              this.settledEnterprises()
              //培训列表
              this.getCourseList()
              //知识产权统计
              this.listProperty()
              //
              this.intellectualStatistics()
              //企业代理机构
              this.volumeStatistics()
              //企业IP数量
              this.enterpriseIP()
            } else {
              // uni.navigateTo({
              // 	url: '/pages/host/index'
              // })
            }
          }
        })
      },
      goPayTrademark(row){
        this.$axios
          .post("/custody/trademark/renewal/payment/order",{
            trademarkRenewalIdSet: row.trademarkRenewalId.split(";")
          })
          .then(({data}) => {
            if(data.code == 0){
              this.$router.push({
                path: '/hostingPlatforms/platformPay',
                query: {
                  order: 'order',
                  renewalOrderNumber: data.data.renewalOrderNumber,
                  type: 'tradmark'
                }
              })
            }
          })
      },
      goPayPatent(row){
        let annuityDetailDtoList =[ {
          id: row.id,
          annuityYearSet:[row.patentYear]
        }]

        this.$axios.post("/custody/annuity/order/create/platform/order/number", annuityDetailDtoList)
          .then(({data})=>{
            if(data.code == 0){
              this.$router.push({
                path: '/hostingPlatforms/platformPay',
                query: {
                  order: 'order',
                  renewalOrderNumber: data.data,
                  type: 'patent'
                }
              })
            }
          })
      },
      tab(item){
        this.list.map(item => {
          item.show = false
          return item
        })
        item.show = true
        this.activeName = item.lable
      },
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      //初始化获取数据
      init_chart(){
        this.$axios
          .post("/custody/home/five/years/summary/list",{
            custodyTypeSet:["trademark","patent"]
          }).then(({data}) => {
          if(data.code == 0){
            let obj = data.data.filter(item => item.applicantName == localStorage.getItem('applicant'))[0]
            this.bar1 = obj.patentFiveYearsCount
            this.bar2 = obj.trademarkFiveYearsCount
          }
        })
      },
      //查询托管数量
      queryHosting() {
        this.$axios
          .post("/custody/home/custody/count",{ applicantName: localStorage.getItem('applicant')}).then(({data}) => {
          if(data.code == 0){
            this.queryHostingArr = data.data
          }
        })
      },
      init_table(){
        this.$axios
          .post("/custody/home/remind/list",{ custodyTypeSet: ["trademark","patent","certificate"]}).then(({data}) => {
            if(data.code == 0){
              let obj = data.data.filter(item => item.applicantName == localStorage.getItem('applicant'))[0]
              this.patentRemindList = obj.patentRemindList
              this.trademarkRemindList =  obj.trademarkRemindList
            }
          })
      },
      handleClick(tab, event) {
      },
      //翻译和商标
      jumpg(value){
        if(value == 1){
          window.open('https://parktm.lanternfish.cn/#/PQ?token=38ca9zztc0cqin0z', '_blank')
        }else {
          window.open('https://parktm.lanternfish.cn/#/PT?token=38ca9zztc0cqin0z', '_blank')
        }
      },
      //网页跳转
      jump(value){
        switch (value) {
          case 1: this.$router.push('/hostingPlatforms/annualFee'); break;
          case 2: this.$router.push('/hostingPlatforms/trademark'); break;
          case 3: this.$router.push('/hostingPlatforms/softness'); break;
          case 4: this.$router.push('/hostingPlatforms/qualifications'); break;
          case 5: this.$router.push('/hostingPlatforms/institution'); break;
        }

      },
      //最近入驻企业
      settledEnterprises(){
        let param = {
          pageNum: 1,
          pageSize: 5
        }
        listEnterprise(param).then(response=>{
          this.settledEnterprisesData = response.rows
        })
      },
      //知识产权信息 listProperty
      listProperty(){
        let param = {
          pageNum: 1,
          pageSize: 5
        }
        listProperty(param).then(response=>{
          this.listPropertyData = response.rows
        })
      },
      //园区首页知识产权管理统计接口
      getAdministration(){
        let param = {
          'memberType': this.$store.state.user.dept.memberType ,
          'memberCode': this.$store.state.user.dept.memberCode
        }
        listAdministration(param).then(response=>{
          if(response.code == 200) {
            this.propertyManagementData = response.data
          }
        })
      },

      /** 查询培训课程列表 */
      getCourseList() {
        listCourse({
          pageNum: 1,
          pageSize: 5,
        }).then(response => {
          this.courseData = response.rows;
        });
      },
      // 企业和机构知识产权统计接口
      intellectualStatistics(){
        let param = {
          'memberType': this.$store.state.user.dept.memberType ,
          'memberCode': this.$store.state.user.dept.memberCode
        }
        statisticsInterface(param).then(response => {
          if(response.code == 200){
            //statYear
            this.statisticsDatas = response.data.sort( (a, b) => {
              return (b.statYear - a.statYear)
            })
            this.statisticsDatass = response.data.sort( (a, b) => {
              return (b.statYear - a.statYear)
            })
            this.enterpriseName = response.data[0].enterpriseName || response.data[0].organizationName
          }
        });
      },
      //代理机构代理量统计
      volumeStatistics(){
        enterpriseQuery().then(response =>{
          if(response.code == 200){
            this.statisticsData = response.data
          }
        })
      },
      //企业IP量统计
      enterpriseIP(){
        enterpriseIP().then(response =>{
          if(response.code == 200){
            this.enterpriseIPData = response.data
          }
        })
      },
      goTarget(href) {
        window.open(href, "_blank");
      }
    }
  };
</script>

<style scoped lang="less">
  .home {
    .selectApplicant {
      /deep/input {
        width: 346px;
        height: 52px;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
        border-radius: 12px 12px 12px 12px;
        font-weight: 600;
        font-size: 16px;
        color: #2D3748;
      }
      /deep/.el-input__icon {
        font-size: 18px;
        color: #000000;
        margin-right: 40px;
      }
    }
    .estimatedExpenses {
      background: url("../../assets/images/hostingPLatform/home/Vector.png");
      background-size: 100% 100%;
      width: 100%;
      height: 220px;
      position: relative;
      padding: 47px 29px 31px 41px;
      box-sizing: border-box;
      .Taltitle {
        position: absolute;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        top: 17px;
        left: 26px;
      }
      .estimatedExpenses_content {
        li {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          p {
            width: 25%;
            text-align: center;
            &.title {
              font-weight: 500;
              font-size: 14px;
              color: #FFFFFF;
              display: block;
              vertical-align: bottom;
            }
            &.data {
              font-weight: 600;
              font-size: 24px;
              color: #FFFFFF;
            }
            &.price {
              font-weight: 600;
              font-size: 24px;
              color: #FFE4B9;
            }
          }
          &:nth-of-type(2) {
            margin-top: 13px;
            border-bottom: 1px dashed #B6BDE3;
            border-bottom-width: 3px;
            padding-bottom: 16px;
            box-sizing: border-box;
            margin-bottom: 19px;
          }
        }
      }
    }
    .custodyVolume {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 18px;
      li {
        width: 47%;
        height: 80px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 19px 17px 0 21px;
        box-sizing: border-box;
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        .title {
          font-weight: 500;
          font-size: 12px;
          color: #30396C;
        }
        .value {
          font-size: 22px;
          color: #2D3748;
          font-weight: 500;
          span {
            color: #BCC8D5;
          }
        }
      }
    }
    .effectiveStatistics {
      height: 416px;
      background: #FFFFFF;
      border-radius: 15px;
    }
    .manage {
      width: 100%;
      height: 303px;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
      border-radius: 15px 15px 15px 15px;
      padding: 18px 27px 0 22px;
      box-sizing: border-box;
      .title {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        ._title_ {
          width: 180px;
          height: 32px;
          background: #E8EDF6;
          border-radius: 8px 8px 8px 8px;
          display: flex;
          span {
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            color: #555D66;
            width: 50%;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              width: 92px;
              height: 32px;
              color: #FFFFFF;
              background: #323B71;
              border-radius: 8px 8px 8px 8px;
            }
          }
        }
        .month {
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 82px;
            height: 29px;
            background: #FCF7EF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #FED99F;
            font-weight: 500;
            font-size: 12px;
            color: #DEA344;
          }
          img {
            width: 22px;
            height: 20px;
            cursor: pointer;
            margin-left: 19px;
          }
        }

      }
      .maturity {
        padding: 14px 21px;
        box-sizing: border-box;
        background: #EEF0FA;
        border-radius: 5px 5px 5px 5px;
        .maturity_title {
          width: calc(100% - 10px);
          margin-bottom: 11px;
          li {
            display: flex;
            padding: 0px 10px;
            box-sizing: border-box;
            span {
              display: block;
              width: 20%;
              font-weight: 500;
              font-size: 14px;
              color: #2D3748;
              &:nth-of-type(1) {
                width: 30%;
              }
              &:nth-last-of-type(1) {
                width: 10%;
              }
          }
          }
        }
        ul:nth-of-type(2) {
          width: 100%;
          height: 160px;
          background: #EEF0FA;
          overflow-y: auto;
          //result-ico
          // 滚动条的宽度
          &::-webkit-scrollbar {
            width: 10px; // 横向滚动条
            height: 10px; // 纵向滚动条 必写
          }

          // 滚动条的滑块
          &::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, .1);
            border-radius: 10px
          }

          &::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, .1)
          }
          li {
            padding: 24px 11px;
            box-sizing: border-box;
            height: 40px;
            background: #FFFFFF;
            /*box-shadow: 1px 17px 44px 0px rgba(0,0,0,0.08);*/
            border-radius: 5px 5px 5px 5px;
            display: flex;
            align-items: center;
            margin-bottom: 11px;
            span {
              display: block;
              align-items: center;
              white-space: nowrap;        /* 禁止换行 */
              overflow: hidden;           /* 隐藏溢出内容 */
              text-overflow: ellipsis;    /* 显示省略号 */
              font-weight: 400;
              font-size: 12px;
              color: #333333;
              width: 20%;
              &:nth-of-type(1) {
                padding-right: 5px;
                box-sizing: border-box;
                width: 30%;
              }
              &:nth-last-of-type(1) {
                width: 10%;
              }
              &.status {
                i.you {
                  width: 53px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #4F5AED;
                  background: #EAECFF;
                  border-radius: 30px;
                }
                i.err {
                  width: 53px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #D12953;
                  background: #FAF0F3;
                  border-radius: 30px;
                }
              }
            }
          }
        }

      }
    }
  }
</style>
